<template>
   
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 云课头条 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="toDetail(1)">新建</a-button>
      </template>
    </HeaderBox>

    <div class="introduce-box">
      <!-- <div class="introduce-title">萱讲头条介绍</div> -->
      <div class="introduce-content">
        <div class="introduce-left">
          <a-textarea
            placeholder="请设置云课头条介绍"
            :rows="4"
            style="resize: none"
            disabled="disabled"
            v-model="introduce"
          />
        </div>
        <div class="introduce-right">
          <a-button class="all_boder_btn" @click="editFilling()">编辑</a-button>
        </div>
      </div>
    </div>

    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :defaultExpandAllRows="true"
      :rowKey="(item) => item.xuanId"
      :loading="loading"
      childrenColumnName="children"
    >
      <template slot="audioTime" slot-scope="item,row">
        <span>{{ row.audioTime != '00:00:00' ? row.audioTime : "-" }}</span>
      </template>

      <template slot="audio" slot-scope="item,row">
        <span>{{ row.xjType == 2 ? "是" : "否" }}</span>
      </template>

      <template slot="show" slot-scope="item, row">
        <span class="blueText">
          <span v-if="row.key != 1 && row.key != 0" @click="moveData(1, row.xuanId)">前移</span>
          <span v-if="row.key != 1 && row.key != 2 && row.key != 0"> | </span>
          <span v-if="row.key != 2 && row.key != 0" @click="moveData(2, row.xuanId)">后移</span>
        </span>
      </template>

      <template slot="catalogue" slot-scope="item, row">
        <span class="blueText">
          <span @click="toDetail(3, row.xuanId)">新建子分类</span> |
          <span @click="toDetail(2, row.xuanId)">编辑</span> |
          <span @click="deleteData(row)">删除</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width="530px"
      title="编辑云课头条"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <div class="right_Div">
            <a-textarea
              placeholder="云课头条介绍"
              :rows="4"
              style="resize: none"
              v-model="content"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="editOk">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "标题名称",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "时长",
    align: "center",
    dataIndex: "audioTime",
    key: "audioTime",
    scopedSlots: { customRender: "audioTime" },
  },
   {
    title: "主讲老师",
    align: "center",
    dataIndex: "lecturer",
    key: "lecturer",
  },
  {
    title: "是否含音频",
    align: "center",
    dataIndex: "audio",
    key: "audio",
    scopedSlots: { customRender: "audio" },
  },
  {
    title: "终端展示排序",
    align: "center",
    dataIndex: "sequence",
    key: "show",
    scopedSlots: { customRender: "show" },
  },
  {
    title: "目录操作",
    align: "center",
    dataIndex: "catalogue",
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      loading: false,
      edit: false,
      columns,
      data: [],
      introduce: "",
      content: "",
      preachId: "",
    };
  }, // 事件处理器
  methods: {
    expandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e, i) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      } else {
        return <span style={{ marginRight: 8 }}></span>;
      }
    },
    toDetail(operation, id) {
      this.$router.push(
        "/admin/contentConfig/preachDetail?" +
          "operation=" +
          operation +
          "&id=" +
          id
      );
    },
    editOk(e) {
      this.edit = false;
      this.$ajax({
        url: "/hxclass-management/speak/introduce/update",
        method: "put",
        params: {
          content: this.content,
          id: this.preachId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = this.content;
        }
      });
    },
    editCancel(e) {
      this.edit = false;
    },
    editFilling() {
      let txt = JSON.stringify(this.introduce);
      this.content = JSON.parse(txt);
      this.edit = true;
    },
    getIntroduce(e) {
      this.$ajax({
        url: "/hxclass-management/speak/introduce",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = res.data.content;
          this.preachId = res.data.id;
        }
      });
    },
    getList(e) {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/speak/list",
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.data = res.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    moveData(e, id) {
      this.$ajax({
        url: "/hxclass-management/speak/update/sort",
        method: "put",
        params: {
          id: id,
          type: e, // 1：上移 2：下移
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deleteData(e) {
      this.$confirm({
        title: "确定删除该目录吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/speak/del?type=" + e.xjType + "&xuan_id=" + e.xuanId,
            method: "delete",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList();
    this.getIntroduce();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .introduce-box {
    margin-top: 24px;
    .introduce-title {
      font-size: 14px;
      color: #000;
      font-weight: 500;
    }
    .introduce-content {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      .introduce-left {
        textarea.ant-input {
          width: 1000px;
        }
        textarea.ant-input {
          height: 80px;
        }
      }
      .introduce-right {
      }
    }
  }
  .btn {
    margin: 0 24px 14px 0;
  }
  a {
    color: #000000;
  }
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  border-top: none;
  text-align: center;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
  textarea.ant-input {
    width: 448px;
  }
  textarea.ant-input {
    height: 120px;
  }
}
</style>
